<template>
  <v-app v-cloak v-scroll="onScroll" v-resize="onResize">
    <div v-if="isLoaded && !groupId" class="sw-text text-center pa-12">
      {{ $vuetify.lang.t("$vuetify.noEventFoundText") }}
    </div>
    <template v-if="groupId">
      <AppToolbar />
      <AppNavigationDrawer />
      <v-row no-gutters class="fill-height" :style="cssVariables">
        <v-col cols="12">
          <v-main>
            <transition name="fade" mode="out-in">
              <router-view></router-view>
            </transition>
          </v-main>
        </v-col>
        <v-col cols="12" class="sw-mt-auto">
          <AppFooter />
        </v-col>
      </v-row>
    </template>
  </v-app>
</template>

<script>
import AppToolbar from "@/components/AppToolbar.vue";
import AppNavigationDrawer from "@/components/AppNavigationDrawer.vue";
import AppFooter from "@/components/AppFooter.vue";
import { localize } from "vee-validate/dist/vee-validate.full";
import EventBus from "@/utils/eventBus";

export default {
  data: () => ({
    isLoaded: false,
  }),
  computed: {
    appDefaultTitle() {
      return process.env.VUE_APP_DEFAULT_TITLE;
    },
    groupId() {
      return this.$store?.getters?.group?.id;
    },
    userToken() {
      return this.$store?.getters?.userToken;
    },
    isUserSubscribed() {
      return this.$store?.getters?.user?.groups?.includes(this.groupId);
    },
    isContactFormEnabled() {
      return this.$store?.getters?.group?.attributes?.lp_contact_form;
    },
    appLanguage() {
      return this.$store?.getters?.appLanguage;
    },
  },
  components: {
    AppToolbar,
    AppNavigationDrawer,
    AppFooter,
  },
  watch: {
    userToken: {
      immediate: true,
      async handler(val) {
        try {
          if (val) {
            await this.getUserSilent();
          }

          await this.getNavigationSilent();
        } catch (error) {
          if (error) {
            EventBus.$emit("showNotification", {
              type: "error",
              message: this.$vuetify.lang.t("$vuetify.internalServerError"),
            });
          }
        }
      },
    },
    appLanguage: {
      async handler(newVal, oldVal) {
        if (!newVal || newVal === oldVal) return;

        this.$vuetify.lang.current = newVal;
        localize(newVal);

        try {
          await this.getGroupAttributesSilent();
          await this.getNavigationSilent();
        } catch (error) {
          if (error) {
            EventBus.$emit("showNotification", {
              type: "error",
              message: this.$vuetify.lang.t("$vuetify.internalServerError"),
            });
          }
        }
      },
    },
    isUserSubscribed: {
      async handler(val) {
        if (!val) return;

        try {
          await this.getNavigationSilent();
        } catch (error) {
          if (error) {
            EventBus.$emit("showNotification", {
              type: "error",
              message: this.$vuetify.lang.t("$vuetify.internalServerError"),
            });
          }
        }
      },
    },
  },
  async mounted() {
    this.$nextTick(() => {
      this.$store.commit(
        "SET_SCROLL_Y",
        document.body.getBoundingClientRect().top,
      );
    });

    try {
      this.getAppNameSilent();

      await this.getGroupSilent();

      this.isLoaded = true;
    } catch (error) {
      if (error) {
        EventBus.$emit("showNotification", {
          type: "error",
          message: this.$vuetify.lang.t("$vuetify.internalServerError"),
        });
      }
    }
  },
  methods: {
    async getGroupSilent() {
      const queryProp = this.findQueryProp("props");
      const props = JSON.parse(queryProp);

      if (props) {
        this.$store.commit("SET_GROUP", props);
      } else {
        const params = {
          domain:
            process.env.VUE_APP_DEFAULT_DOMAIN || window.location.hostname,
          with_attributes: 1,
          expand_attributes: 1,
        };

        const response = await this.$http.get(`/groups/by_domain`, {
          params,
        });

        const group = response?.data?.data || {};

        if (!this.groupId || group.id !== this.groupId) {
          this.$store.commit("SET_GROUP", group);
        }

        document.title = group.name || this.appDefaultTitle;
      }
    },
    async getGroupAttributesSilent() {
      const params = {
        lang: this.appLanguage,
        with_attributes: 1,
        expand_attributes: 1,
      };

      const response = await this.$http.get(`/groups/${this.groupId}`, {
        params,
      });

      let group = response?.data?.data || {};

      this.$store.commit("SET_GROUP_ATTRIBUTES", group.attributes || {});
    },
    async getAppNameSilent() {
      const response = await this.$http.get(`/settings/public/app.name`);

      const appName = response?.data?.data?.value || "";

      this.$store.commit("SET_APP_NAME", appName);
    },
    async getUserSilent() {
      const params = { with_attributes: 1 };

      const response = await this.$http.get(`/auth/me`, { params });

      const user = response?.data?.data || { lang_pref: "" };

      this.$store.commit("SET_USER", user);
    },
    async getNavigationSilent() {
      if (!this.groupId) return;

      const params = {
        lang: this.appLanguage,
      };

      const response = await this.$http.get(`/groups/${this.groupId}/menus`, {
        params,
      });

      const menu = response.data.data.find(
        (el) => el.slug === "ionic-app-sidemenu",
      );

      const supportedComponents = [
        "FrontPage",
        "MessageListPage",
        "MessagePage",
        "TicketPage",
        "ArticleListPage",
        "ArticlePage",
        "QuestionPage",
        "QuestionaryReportPage",
      ];

      menu.destinations = menu.destinations.filter((el) =>
        supportedComponents.includes(el.component),
      );

      if (this.isContactFormEnabled) {
        menu.destinations.push({
          name: this.$vuetify.lang.t("$vuetify.contactUs"),
          component: "ContactPage",
        });
      }

      this.$store.commit("SET_NAVIGATION", menu);
    },
    onScroll() {
      this.$store.commit(
        "SET_SCROLL_Y",
        document.body.getBoundingClientRect().top,
      );
    },
    onResize() {
      this.$store.commit("SET_WINDOW_HEIGHT", window.innerHeight);
      this.$store.commit("SET_WINDOW_WIDTH", window.innerWidth);
    },
    findQueryProp(parameterName) {
      let result = null;
      let tmp = [];

      location.search
        ?.substr(1)
        ?.split("&")
        ?.forEach(function(item) {
          tmp = item.split("=");
          if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });

      return result;
    },
  },
};
</script>

<style lang="scss" scoped></style>
