import Vue from "vue";

Vue.mixin({
  computed: {
    gmPrimaryColor() {
      return this.$store?.getters?.group?.attributes?.lp_primary_color;
    },
    gmSecondaryColor() {
      return this.$store?.getters?.group?.attributes?.lp_secondary_color;
    },
    gmToolbarHeight() {
      const windowWidth = this.$store?.getters?.windowWidth;

      if (windowWidth <= 900) {
        return 90;
      }
      return 125;
    },
    cssVariables() {
      return {
        "--primary-color": this.gmPrimaryColor,
        "--secondary-color": this.gmSecondaryColor,
      };
    },
  },
  methods: {
    convertToPlain(html) {
      if (!html) return "";
      return html.replace(/<[^>]+>/g, "");
    },
    scrollToEl(id, offset = 0) {
      if (!id) return;

      const element = document.querySelector(id);
      const y = element?.getBoundingClientRect().top + window.scrollY - offset;

      window.scroll({
        top: y,
        behavior: "smooth",
      });
    },
  },
});
