export default {
  noDataText: "No data available",
  noEventFoundText: "Event is not available",
  noLanguagesSelectedText: "Not selected",
  loadingText: "Loading...",
  iso2: {
    en: "English",
    da: "Dansk",
    de: "Deutsch",
    ru: "Русский",
    lv: "Latviski",
  },
  countdown: {
    days: "Days",
    minutes: "Minutes",
    hours: "Hours",
    seconds: "Seconds",
    day: "Day",
    minute: "Minute",
    hour: "Hour",
    second: "Second",
  },
  homeNavigationItem: "Home",
  readMore: "Read more",
  createdBy: "Powered by",
  enterYourEmail: "Please enter your email",
  email: "Email",
  pin: "Verification code",
  startRegistration: "Start registration",
  verificationHeadline: "Please insert verification code sent to",
  codeFromEmail: "Code from your email!",
  name: "First name",
  surname: "Last name",
  phoneCode: "Country code",
  dialCodeHeader: "Select or type to search",
  phone: "Mobile number",
  preferredLanguages: "Preferred languages",
  termsAndConditions: "Privacy Disclaimer",
  iAcceptTermsAndConditions:
    "I have read and understood the information on the privacy disclaimer",
  confirmationHeadline: "Is this information correct?",
  editInformationText: "Click on your answer to change it",
  payment: "Payment",
  invoiceDetails: "Invoice details",
  state: "Country/region",
  street: "Street address",
  postalCode: "Postal code",
  profileHeadline: "Your profile",
  update: "Update",
  bookAsCompany: "Book as a company",
  bookAsPrivatePerson: "Book as private person",
  otherDeliveryInformation: "Other delivery information?",
  finishRegistration: "Finish registration",
  summary: "Summary",
  totalExcludingVat: "Total sum excl. VAT",
  totalIncludingVat: "Total sum incl. VAT",
  vat: "VAT",
  totalAmount: "Total amount:",
  includingVat: "incl. VAT",
  paymentDetails: "Insert your payment information",
  cardExpiry: "Card expiration",
  cardExpiryPlaceholder: "MM / YY",
  cardCvc: "CVC",
  smsVerification: "SMS verification",
  emailVerification: "Email verification",
  enterPin: "Enter code",
  userNotFound: "User does not exist",
  emailAlreadyTaken: "The email has already been taken",
  phoneAlreadyTaken: "The phone has already been taken",
  invalidVerificationCode: "Invalid verification code",
  emailVerificationLinkText: "Click here for email verification",
  smsVerificationLinkText: "Click here for SMS verification",
  getVerificationLinkText: "Get verification",
  chooseAnswer: "Select answer",
  writeAnswer: "Write answer",
  yourAnswers: "Your answers",
  yourOrder: "Your order",
  accept: "Accept",
  cancel: "Cancel",
  send: "Send",
  back: "Back",
  next: "Next",
  continue: "Continue",
  edit: "Edit",
  save: "Save",
  logIn: "Log in",
  logOut: "Log out",
  selectMore: "Select more",
  forbiddenHeadLine: "Forbidden",
  forbiddenText: "You don't have permission to access this page.",
  goToHomePage: "Go to frontpage",
  paymentSuccessful: "Payment successful",
  paymentError: "Something went wrong",
  internalServerError: "Server error. Please contact support.",
  close: "Close",
  addPaymentInformation: "Add payment information",
  paymentMethod: "Payment method",
  addCompanyDetails: "Add company details",
  creditCard: "Credit/debit card",
  creditCardNumber: "Credit/debit card number",
  contactUs: "Contact us",
  contactUsDescription:
    "If you have questions or want to change your registration, please send us a message.",
  nameSurname: "Name, Surname",
  message: "Message",
  continueAndPay: "Continue and pay",
  yourTicket: "Your ticket",
  startQuiz: "Begin",
  finishQuiz: "Finish",
  emailUser: "{_field_} is not valid",
  menu: "Menu",
  loginSuccessfullyDone: "Successfully logged in",
  tokenExpired: "Your automatic login token has expired",
  confirmation: "Let's comfim",
  registrationConfirmation: "Do you want to finish registration now?",
  emptyMessageListText: "You don't have any messages yet.",
  loadingRegistration: "Loading registration...",
  hiddenQuestionErrorMessage:
    "Oops! It seems like there's some missing information. Please double-check your details. If the issue persists, kindly reach out to your event team for assistance. We apologize for any inconvenience.",
};
