export default {
  noDataText: "Ingen data er tilgængelig",
  noEventFoundText: "Eventet er ikke tilgængelig",
  noLanguagesSelectedText: "Ikke valgt",
  loadingText: "Indlæser",
  iso2: {
    en: "English",
    da: "Dansk",
    de: "Deutsch",
    ru: "Русский",
    lv: "Latviski",
  },
  countdown: {
    days: "Dage",
    minutes: "Minutter",
    hours: "Timer",
    seconds: "Sekunder",
    day: "Dag",
    minute: "Minut",
    hour: "Time",
    second: "Sekund",
  },
  homeNavigationItem: "Hjem",
  readMore: "Læs mere",
  createdBy: "Powered by",
  enterYourEmail: "Indtast din e-mail",
  email: "E-mail",
  pin: "Verifikations kode",
  startRegistration: "Start registrering",
  verificationHeadline: "Indsæt verifikationskode sendt til",
  codeFromEmail: "Koden fra din e-mail",
  name: "Fornavn",
  surname: "Efternavn",
  phoneCode: "Landekode",
  dialCodeHeader: "Vælg eller indsæt en anden",
  phone: "Mobilnummer",
  preferredLanguages: "Foretrukne sprog",
  termsAndConditions: "Privacy Disclaimer",
  iAcceptTermsAndConditions:
    "Jeg har læst og forstået oplysninger i privacy disclaimeren",
  confirmationHeadline: "Er disse oplysninger korrekte?",
  editInformationText: "Klik på dit svar for at ændre det",
  payment: "Betaling",
  invoiceDetails: "Information til betaling",
  state: "Land / region",
  street: "Adresse",
  postalCode: "Postnummer",
  profileHeadline: "Din profil",
  update: "Opdatering",
  bookAsCompany: "Bestil som firma",
  bookAsPrivatePerson: "Bestil som privat person",
  otherDeliveryInformation: "Anden leveringmetode",
  finishRegistration: "Afslut registrering",
  summary: "Resumé",
  totalExcludingVat: "Samlet sum ekskl. moms",
  totalIncludingVat: "Samlet sum inkl. moms",
  vat: "Moms",
  totalAmount: "Total beløb",
  includingVat: "inkl. moms",
  paymentDetails: "Indsæt dine betalingsoplysninger",
  cardExpiry: "Udløbsdato",
  cardExpiryPlaceholder: "MM / ÅÅ",
  cardCvc: "CVC",
  smsVerification: "SMS verifikation",
  emailVerification: "E-mail verifikation",
  enterPin: "Indtast kode",
  userNotFound: "Brugeren eksisterer ikke",
  emailAlreadyTaken: "Denne e-mail er allerede i brug",
  phoneAlreadyTaken: "Telefonen er allerede i brug",
  invalidVerificationCode: "Ugyldig verifikations kode",
  emailVerificationLinkText: "Klik her for e-mail verifikation",
  smsVerificationLinkText: "Klik her for SMS verifikation",
  getVerificationLinkText: "Modtag verifikation",
  chooseAnswer: "Vælg svar",
  writeAnswer: "Skriv dit svar",
  yourAnswers: "Dit svar",
  yourOrder: "Din ordre",
  accept: "Acceptér",
  cancel: "Afslut",
  send: "Send",
  back: "Tilbage",
  next: "Næste",
  continue: "Fortsæt",
  edit: "Redigér",
  save: "Gem",
  logIn: "Log på",
  logOut: "Log ud",
  selectMore: "Vælg flere",
  forbiddenHeadLine: "Adgang nægtet",
  forbiddenText: "Du har desværre ikke adgang til denne side.",
  goToHomePage: "Gå til forsiden",
  paymentSuccessful: "Betaling vellykket",
  paymentError: "Noget gik galt",
  internalServerError: "Server Fejl. Kontakt venligst support.",
  close: "Luk",
  addPaymentInformation: "Tilføj betalingsoplysninger",
  paymentMethod: "Betalingsmetode",
  addCompanyDetails: "Tilføj virksomhedsoplysninger",
  creditCard: "Kredit- / betalingskort",
  creditCardNumber: "Kredit- / betalingskortnummer",
  contactUs: "Kontakt os",
  contactUsDescription:
    "Hvis du har spørgsmål eller ønsker at ændre din registrering, bedes du sende os en besked.",
  nameSurname: "Navn, Efternavn",
  message: "Besked",
  continueAndPay: "Fortsæt og betal",
  yourTicket: "Din billet",
  startQuiz: "Begin",
  finishQuiz: "Afslut",
  emailUser: "{_field_} er ikke gyldig",
  menu: "Menu",
  loginSuccessfullyDone: "Logget ind",
  tokenExpired: "Dit automatiske login-token er udløbet",
  confirmation: "Lad os bekræfte",
  registrationConfirmation: "Vil du afslutte registreringen nu?",
  emptyMessageListText: "Du har ingen beskeder endnu.",
  loadingRegistration: "Indlæser registrering...",
  hiddenQuestionErrorMessage:
    "Det ser ud til, at der mangler nogle oplysninger. Tjek venligst dine indtastede oplysninger for fejl. Hvis problemet fortsætter, bedes du kontakte dit eventteam for hjælp.",
};
